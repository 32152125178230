import * as React from "react"

import { PrimaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import { SEO } from "../components/seo"
import { clearSnappy } from "../model/helpers"

const oops = require("../images/oops.gif")

const NotFoundPage = () => (
  <>
    {clearSnappy()}
    <PrimaryHeader />
    <SEO title="404: Not found" url="404" />
    <div className="container">
      <div className="row">
        <div className="col-md-8 col-md-offset-2">
          <div className="body-wrap">
            <h1 className="oops-headline">404: Oooops We Have No Idea What Happened To This File</h1>
            <h2 className="oops-sub-headline">Just Look Away... Wait You Are Still Here... Well This is Awkward... </h2>
            <img src={oops} className="oops-photo" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>
)

export default NotFoundPage
